<template>
  <div  v-loading.fullscreen.lock="loading"></div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Translate',
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    // this.ceshi();
  },
  created() {
    this.ceshi();
  },
  methods: {
    // 测试
    ceshi() {
      this.loading = true;
      if (!localStorage.getItem('AuthToken')) {
        console.log(this.$route.query);
        console.log('到这了1');
        this.loading = true;
        axios
          .get(`/relay/login?code=${this.$route.query.code}`)
          .then((res) => {
            localStorage.setItem('AuthToken', res.data.data.AuthToken);
            localStorage.setItem('UserId', res.data.data.UserId);
            this.nextFrom();
          })
          .catch((err) => {
            console.log(err);
          });
        this.loading = false;
      } else {
        this.nextFrom();
      }
      this.loading = true;
    },
    nextFrom() {
      if (this.$route.query?.toMode === 'replace') {
        if (this.$route.query.from) {
          window.location.replace(decodeURIComponent(this.$route.query.from));
        } else {
          this.$router.replace('/operateManage/business/agency');
        }
      } else {
        if (this.$route.query.from) {
          window.location.href = decodeURIComponent(this.$route.query.from);
        } else {
          this.$router.push('/operateManage/business/agency');
        }
      }
    },
  },
};
</script>

<style>
</style>
